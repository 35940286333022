$(function() {
  $('.custom-scroll-ma[href*="#"]:not([href="#"])').click(function() {
    if (location.pathname.replace(/^\//,'') == this.pathname.replace(/^\//,'') && location.hostname == this.hostname) {
      var target = $(this.hash);
      target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
      if (target.length) {
        $('html, body').animate({
          scrollTop: parseInt(target.offset().top) - 100 + "px"
        }, 1000);
        return false;
      }
    }
  });

  var target = $(location.hash);
  target = target.length ? target : '';
  
  if(target.length){
    $('html, body').animate({
      scrollTop: parseInt(target.offset().top) - 100 + "px"
    }, 800);
  }
});
